import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faBalanceScale as freeFasFaBalanceScale,
  faCaretDown as freeFasFaCaretDown,
  faEnvelope as freeFasFaEnvelope,
  faEraser as freeFasFaEraser,
  faEye as freeFasFaEye,
  faHeading as freeFasFaHeading,
  faHome as freeFasFaHome,
  faLock as freeFasFaLock,
  faPencilAlt as freeFasFaPencilAlt,
  faPlusCircle as freeFasFaPlusCircle,
  faSearch as freeFasFaSearch,
  faSignOutAlt as freeFasFaSignOutAlt,
  faTrashAlt as freeFasFaTrashAlt,
  faUserCog as freeFasFaUserCog,
  faUserFriends as freeFasFaUserFriends,
  faUserTie as freeFasFaUserTie,
  faAngleRight as freeFasFaAngleRight
} from '@fortawesome/free-solid-svg-icons'

library.add(
  freeFasFaBalanceScale,
  freeFasFaCaretDown,
  freeFasFaEnvelope,
  freeFasFaEraser,
  freeFasFaEye,
  freeFasFaHeading,
  freeFasFaHome,
  freeFasFaLock,
  freeFasFaPencilAlt,
  freeFasFaPlusCircle,
  freeFasFaSearch,
  freeFasFaSignOutAlt,
  freeFasFaTrashAlt,
  freeFasFaUserCog,
  freeFasFaUserFriends,
  freeFasFaUserTie,
  freeFasFaAngleRight
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
