
import Footer from '~/components/layouts/Footer'
import Header from '~/components/layouts/Header'
import Sidebar from '~/components/layouts/Sidebar'

export default {
  components: {
    Footer,
    Header,
    Sidebar
  },

  middleware: ['auth'],

  computed: {
    locale() {
      const locale = this.$i18n.locales.find(
        item => item.code === this.$i18n.locale
      )
      const localeISO = locale ? locale.iso : 'en-US'
      const provider = require(`ant-design-vue/es/locale-provider/${localeISO.replace(
        '-',
        '_'
      )}`)
      return provider.default
    }
  }
}
