
export default {
  data() {
    return {}
  },

  methods: {
    goToTickets() {
      this.$router.push('/tickets')
    }
  }
}
