export const LayoutsFooter = () => import('../../components/layouts/Footer.vue' /* webpackChunkName: "components/layouts-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsHeader = () => import('../../components/layouts/Header.vue' /* webpackChunkName: "components/layouts-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsSidebar = () => import('../../components/layouts/Sidebar.vue' /* webpackChunkName: "components/layouts-sidebar" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserBanner = () => import('../../components/layouts/UserBanner.vue' /* webpackChunkName: "components/layouts-user-banner" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserFooter = () => import('../../components/layouts/UserFooter.vue' /* webpackChunkName: "components/layouts-user-footer" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserHeader = () => import('../../components/layouts/UserHeader.vue' /* webpackChunkName: "components/layouts-user-header" */).then(c => wrapFunctional(c.default || c))
export const LayoutsUserOffcanvasMenu = () => import('../../components/layouts/UserOffcanvasMenu.vue' /* webpackChunkName: "components/layouts-user-offcanvas-menu" */).then(c => wrapFunctional(c.default || c))
export const MoleculesMenuItem = () => import('../../components/molecules/MenuItem.vue' /* webpackChunkName: "components/molecules-menu-item" */).then(c => wrapFunctional(c.default || c))
export const MoleculesSvgIcon = () => import('../../components/molecules/SvgIcon.vue' /* webpackChunkName: "components/molecules-svg-icon" */).then(c => wrapFunctional(c.default || c))
export const OrganismsBuyNowLink = () => import('../../components/organisms/BuyNowLink.vue' /* webpackChunkName: "components/organisms-buy-now-link" */).then(c => wrapFunctional(c.default || c))
export const OrganismsNewsDetailBanner = () => import('../../components/organisms/NewsDetailBanner.vue' /* webpackChunkName: "components/organisms-news-detail-banner" */).then(c => wrapFunctional(c.default || c))
export const OrganismsNewsList = () => import('../../components/organisms/NewsList.vue' /* webpackChunkName: "components/organisms-news-list" */).then(c => wrapFunctional(c.default || c))
export const OrganismsSubMenu = () => import('../../components/organisms/SubMenu.vue' /* webpackChunkName: "components/organisms-sub-menu" */).then(c => wrapFunctional(c.default || c))
export const TemplatesRoleForm = () => import('../../components/templates/RoleForm.vue' /* webpackChunkName: "components/templates-role-form" */).then(c => wrapFunctional(c.default || c))
export const TemplatesUserForm = () => import('../../components/templates/UserForm.vue' /* webpackChunkName: "components/templates-user-form" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHomeEvent = () => import('../../components/organisms/home/Event.vue' /* webpackChunkName: "components/organisms-home-event" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHomeNews = () => import('../../components/organisms/home/News.vue' /* webpackChunkName: "components/organisms-home-news" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHomeOfficialSponsor = () => import('../../components/organisms/home/OfficialSponsor.vue' /* webpackChunkName: "components/organisms-home-official-sponsor" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHomeOfficialSupporter = () => import('../../components/organisms/home/OfficialSupporter.vue' /* webpackChunkName: "components/organisms-home-official-supporter" */).then(c => wrapFunctional(c.default || c))
export const OrganismsHomeTopBanner = () => import('../../components/organisms/home/TopBanner.vue' /* webpackChunkName: "components/organisms-home-top-banner" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
