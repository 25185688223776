
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      visibleUserOffcanvasMenu: 'visibleUserOffcanvasMenu'
    })
  },

  methods: {}
}
