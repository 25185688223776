

export const Constants = {
  COMPONENT_OPTIONS_KEY: "nuxtI18n",
  STRATEGIES: {"PREFIX":"prefix","PREFIX_EXCEPT_DEFAULT":"prefix_except_default","PREFIX_AND_DEFAULT":"prefix_and_default","NO_PREFIX":"no_prefix"},
  REDIRECT_ON_OPTIONS: {"ALL":"all","ROOT":"root","NO_PREFIX":"no prefix"},
}
export const nuxtOptions = {
  isUniversalMode: true,
  trailingSlash: undefined,
}
export const options = {
  vueI18n: {"fallbackLocale":"ja","messages":{"ja":{"common.action":"アクション","common.cancel":"キャンセル","common.clear":"クリア","common.create":"作成","common.delete":"削除","common.edit":"編集","common.home":"トップページへ","common.login":"ログイン","common.logout":"ログアウト","common.new":"新規投稿","common.no":"番号","common.profile":"プロファイル","common.search":"検索","common.update":"更新","common.yes":"はい","system":"System","module":"Module","error.403":"申し訳ございませんが、ページが見つかりませんでした。","error.404":"お探しのページは見つかりませんでした。","error.500":"申し訳ありませんが、サーバーエラーが発生しました。","module.role":"役割","module.user":"ユーザー","pagination.total":"合計{total}アイテム","role.name":"名前","role.permissions":"権限","text.confirm_to_delete":"このアイテムを削除してもよろしいですか？","text.something_wrong":"問題が発生しました","text.successfully":"正常に","user.email":"メール","user.name":"名前","user.code":"Code","user.username":"Username","user.password_confirm":"パスワード確認","user.password":"パスワード","user.roles":"役割","user.index":"List","user":"User","role":"Role","role.index":"List","validation.invalid_email":"メールは有効なメールではありません","validation.min":"{field}は少なくとも{min}文字である必要があります","validation.not_match":"{field1}と{field2}が一致しません","validation.required":"{field}が必要です","module.menu":"Menu"}},"silentTranslationWarn":false},
  vueI18nLoader: false,
  locales: [{"code":"ja","iso":"ja-JP"}],
  defaultLocale: "ja",
  defaultDirection: "ltr",
  routesNameSeparator: "___",
  defaultLocaleRouteNameSuffix: "default",
  sortRoutes: true,
  strategy: "no_prefix",
  lazy: false,
  langDir: null,
  rootRedirect: null,
  detectBrowserLanguage: {"alwaysRedirect":false,"cookieAge":365,"cookieCrossOrigin":false,"cookieDomain":null,"cookieKey":"i18n_redirected","cookieSecure":false,"fallbackLocale":"","redirectOn":"root","useCookie":true},
  differentDomains: false,
  baseUrl: "",
  vuex: {"moduleName":"i18n","syncRouteParams":true},
  parsePages: true,
  pages: {},
  skipSettingLocaleOnNavigate: false,
  onBeforeLanguageSwitch: () => {},
  onLanguageSwitched: () => null,
  normalizedLocales: [{"code":"ja","iso":"ja-JP"}],
  localeCodes: ["ja"],
  additionalMessages: [],
}

export const localeMessages = {}
